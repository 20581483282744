import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';

import { VehicleService } from 'src/app/services/vehicle.service';
import { PhotoService } from './../../services/photo.service';
import { HttpEventType } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';

declare var $: any;

@Component({
  templateUrl: './view-vehicle.component.html'
})
export class ViewVehicleComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  vehicle: any;
  vehicleId: number;
  photos: any;
  progress: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasty: ToastrService,
    private vehicleService: VehicleService,
    private photoService: PhotoService,
    private zone: NgZone,
    private auth: AuthService) {

    route.params.subscribe(p => {
      this.vehicleId = ~~p['id'];
      if (isNaN(this.vehicleId) || this.vehicleId <= 0) {
        router.navigate(['/vehicles']);
        return;
      }
    });
  }

  ngOnInit() {
    this.vehicleService.getVehicle(this.vehicleId)
      .subscribe(
        v => this.vehicle = v,
        err => {
          if (err.status == 404) {
            this.router.navigate(['/vehicles']);
            return;
          }
        });

    this.photoService.getPhotos(this.vehicleId)
      .subscribe(photos => { this.photos = photos });
  }

  delete() {
    this.vehicleService.delete(this.vehicle.id)
      .subscribe(data => {
        $('#deleteVehicle').modal('hide');
        this.router.navigate(['/vehicles']);
      });
  }

  uplaodPhoto() {
    this.updateFileInputLabel();

    var nativeElement: HTMLInputElement = this.fileInput.nativeElement;
    
    this.photoService.upload(this.vehicleId, nativeElement.files[0])
      .subscribe(
        data => {
          if (data.type === HttpEventType.Response) {
            this.photos.push(data['body']);
          }
          if (data.type === HttpEventType.UploadProgress) {
            const total = data.total;
            const percentage = Math.round(data.loaded / data.total * 100);
            this.zone.run(() => {
              this.progress = { total: total, percentage: percentage };
            });
          }
        },
        err => {
          this.toasty.error(err.error, 'Error!', {
            timeOut: 3000
          })
          .onHidden.subscribe(x => this.clearFileInputLabel());
        },
        () => {
          this.progress = null;
          this.clearFileInputLabel();
        });
  }

  updateFileInputLabel() {
    $(document).on('change', '.custom-file-input', function (e) {
      $(this).next('.custom-file-label').html(e.target.files[0].name);
    });
  }

  clearFileInputLabel() {
    $('#photo').next('.custom-file-label').html('Choose file');
  }

}
