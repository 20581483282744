import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { VehicleService } from 'src/app/services/vehicle.service';
import { AuthService } from 'src/app/services/auth.service';
import { Vehicle, KeyValuePair } from './../../models/vehicle';

@Component({
  templateUrl: './vehicle-list.component.html'
})
export class VehicleListComponent implements OnInit {

  private readonly PAGE_SIZE = 5;

  queryResult: any = {};
  makes: any; // KeyValuePair[];
  query: any = {
    pageSize: this.PAGE_SIZE
  };
  columns = [
    { title: 'Id' },
    { title: 'Make', key: 'make', isSortable: true },
    { title: 'Model', key: 'model', isSortable: true },
    { title: 'Contact Name', key: 'contactName', isSortable: true },
    { title: 'Action' }
  ];

  constructor(private vehicleService: VehicleService,
    private router: Router, private auth: AuthService) { }

  ngOnInit() {
    this.vehicleService.getMakes()
      .subscribe(makes => this.makes = makes);

    this.populateVehicles();
  }

  private populateVehicles() {
    this.vehicleService.getVehicles(this.query)
      .subscribe(result => this.queryResult = result);
  }

  onFilterChange() {
    // this.query.modelId = 2; // testing the filtering by ModelId
    this.query.page = 1;
    this.populateVehicles();
  }

  resetFileter() {
    this.query = {
      page: 1,
      pageSize: this.PAGE_SIZE
    };
    this.populateVehicles();
  }

  sortBy(columnName) {
    if (this.query.sortBy === columnName) {
      this.query.isSortAscending = !this.query.isSortAscending;
    } else {
      this.query.sortBy = columnName;
      this.query.isSortAscending = true;
    }

    this.populateVehicles();
  }

  onPageChange(page) {
    this.query.page = page;
    this.populateVehicles();
  }

  delete(id: number) {
    if (confirm('Are you sure?')) {
      this.vehicleService.delete(id)
        .subscribe(data => {
          this.router.navigate(['/vehicles']);
        });
    }
  }

}
