import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(protected auth: AuthService, private router: Router) { }

  canActivate(): boolean {
    if (!this.auth.isLoggedIn) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }

}