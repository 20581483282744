// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    clientID: 'TXGJQMkSsu9z6h1FnQ4XNcb08j1bNhVL',
    domain: 'youcefdeveloper.us.auth0.com', // e.g., you.auth0.com
    audience: 'https://vegaapi.youcefdeveloper.com',
    auth0RedirectUri: window.location.origin, // URL to return to after auth0 login
    auth0ReturnTo: window.location.origin, // URL to return to after auth0 logout
    scope: 'openid profile',
    claim: 'https://vegaapp.youcefdeveloper.com/roles'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
