import * as _ from 'underscore';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { VehicleService } from 'src/app/services/vehicle.service';
import { SaveVehicle } from './../../models/vehicle';

declare var $: any;

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.css']
})
export class VehicleFormComponent implements OnInit {
  makes: any;
  models: any;
  features: any;
  vehicle: SaveVehicle = {
    id: 0,
    makeId: 0,
    modelId: 0,
    isRegistered: false,
    features: [],
    contact: {
      name: '',
      email: '',
      phone: ''
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vehicleService: VehicleService,
    private toastrService: ToastrService) {

    this.route.params.subscribe(p => {
      this.vehicle.id = ~~p['id'];
    });
  }

  ngOnInit() {
    var sources = [
      this.vehicleService.getMakes(),
      this.vehicleService.getFeatures()
    ];

    if (this.vehicle.id)
      sources.push(this.vehicleService.getVehicle(this.vehicle.id));

    forkJoin(sources).subscribe(data => {
      this.makes = data[0];
      this.features = data[1];

      if (this.vehicle.id) {
        this.setVehicle(data[2]);
        this.populateModels();
      }
    }, err => {
      if (err.status == 404)
        this.router.navigate(['/vehicles']);
    });
  }

  private setVehicle(v) {
    this.vehicle.id = v.id;
    this.vehicle.makeId = v.make.id;
    this.vehicle.modelId = v.model.id;
    this.vehicle.isRegistered = v.isRegistered;
    this.vehicle.contact = v.contact;
    this.vehicle.features = _.pluck(v.features, 'id');
  }

  onMakeChange() {
    this.populateModels();
    delete this.vehicle.modelId;
  }

  private populateModels() {
    var selectedMake = this.makes.find(m => m.id == this.vehicle.makeId);
    this.models = selectedMake ? selectedMake.models : [];
  }

  onFeatureToggle(featureId, $event) {
    if ($event.target.checked)
      this.vehicle.features.push(featureId);
    else {
      var index = this.vehicle.features.indexOf(featureId);
      this.vehicle.features.splice(index, 1);
    }
  }

  submit() {
    var result$ = (this.vehicle.id) ? this.vehicleService.update(this.vehicle) : this.vehicleService.create(this.vehicle);
    var message = (this.vehicle.id) ? 'The vehicle has been updated' : 'The vehicle has been added';
    result$.subscribe(vehicle => {
      this.toastrService.success(message, 'Success!', {
        timeOut: 3000
      })
      .onHidden.subscribe(x => this.router.navigate(['/vehicles']));
    });
  }

  delete() {
    this.vehicleService.delete(this.vehicle.id)
      .subscribe(data => {
        $('#deleteVehicle').modal('hide');
        this.router.navigate(['/vehicles']);
      })
  }

  clearForm() {
    this.vehicle.id = 0;
    this.vehicle.makeId = 0;
    this.vehicle.modelId = 0;
    this.vehicle.isRegistered = false;
    this.vehicle.features = [];
    this.vehicle.contact = {
      name: '',
      email: '',
      phone: ''
    }
  }
}
