import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuardService extends AuthGuardService {
  isAdmin: boolean = null;

  constructor(auth: AuthService, router: Router) {
    super(auth, router);
  }

  canActivate(): boolean {
    var isAuthenticated = super.canActivate;
    
    return isAuthenticated ? this.auth.isInRole('Admin') : false;
  }

}
