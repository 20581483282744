import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  template: `
  <hr />
  <div class="row">
    <div class="col-md-5 mt-2">
      <p *ngIf="totalItems" class="font-weight-light">Showing {{ (currentPage-1 ) * pageSize + 1}} to {{ (pageSize * currentPage < totalItems) ?  pageSize * currentPage : totalItems }} of {{ totalItems }} entries</p>
    </div>
    <div class="col-md-7">
      <nav *ngIf="totalItems > pageSize" class="float-md-right mt-2 mt-md-0">
        <ul class="pagination mb-2">
            <li class="page-item" [class.disabled]="currentPage == 1">
              <a (click)="previous()" class="page-link" aria-label="Previous">
                <span aria-hidden="true">&#8249;</span>
              </a>
            </li>
            <li class="page-item" [class.active]="currentPage == page" *ngFor="let page of pages" (click)="changePage(page)">
              <a class="page-link">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage == pages.length">
              <a (click)="next()" class="page-link" aria-label="Next">
                <span aria-hidden="true">&#8250;</span>
              </a>
            </li>
        </ul>
      </nav>
    </div>
  </div>
  `
})
export class PaginationComponent implements OnChanges {

  @Input('total-items') totalItems;
  @Input('page-size') pageSize;
  @Output('page-changed') pageChanged = new EventEmitter();
  pages: any[];
  currentPage = 1;

  constructor() { }

  ngOnChanges() {
    this.currentPage = 1;

    var pagesCount = Math.ceil(this.totalItems / this.pageSize);
    this.pages = [];
    for (var i = 1; i <= pagesCount; i++)
      this.pages.push(i);
  }

  changePage(page) {
    this.currentPage = page;
    this.pageChanged.emit(page);
  }

  previous() {
    if (this.currentPage == 1)
      return;

    this.currentPage--;
    this.pageChanged.emit(this.currentPage);
  }

  next() {
    if (this.currentPage == this.pages.length)
      return;

    this.currentPage++;
    this.pageChanged.emit(this.currentPage);
  }

}
