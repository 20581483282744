import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

import { AuthService } from './../../services/auth.service';

@Component({
  template: `
    <div class="row mt-4 mb-4">
      <div class="col-6">
        <h2>Dashboard</h2>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <canvas id="pieChart"></canvas>
      </div>
    </div>
  `,
})
export class AdminComponent implements OnInit {

  PieChart = [];

  constructor(public auth: AuthService) { }

  ngOnInit() {
    this.PieChart = new Chart('pieChart', {
      type: 'pie',
      data: {
        labels: ['BMW', 'Audi', 'Mazda'],
        datasets: [{
          label: 'Vehicles',
          data: [5, 3, 1],
          backgroundColor: [
            "#ff6384",
            "#36a2eb",
            "#ffce56"
          ]
        }]
      },
      options: {
        title: {
          Text: 'Bar Chart',
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              begainAtZero: true
            }
          }]
        }
      }
    })
  }

}
