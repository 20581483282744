import { Component, OnInit } from '@angular/core';

import { AuthService } from './../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
    // this.auth.handleLoginCallback();

    // toggle nav menu
    $('.hamburger-menu').on('click', function () {
      $(this).toggleClass('open');
    });

    // open dropdown menu
    $('.dropdown').on('show.bs.dropdown', function (e) {
      $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
    });

    // close dropdown menu
    $('.dropdown').on('hide.bs.dropdown', function (e) {
      $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
    });
  }
}
