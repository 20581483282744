import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

declare var $: any;

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private auth: AuthService) { }

  ngOnInit() {
    $(document).on('change', '.custom-file-input', function (e) {
      $(this).next('.custom-file-label').html(e.target.files[0].name);
    });
  }

}
