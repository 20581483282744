import * as Raven from "raven-js";

import { ErrorHandler, Inject, NgZone, isDevMode } from "@angular/core"

import { ToastrService } from 'ngx-toastr';

export class AppErrorHandler implements ErrorHandler {

    constructor(
        private ngZone: NgZone, 
        @Inject(ToastrService) private toastrService: ToastrService) { }

    handleError(error: any): void {
        this.ngZone.run(() => {
            this.toastrService.error('An unexpected error happened', 'Error!', {
              timeOut: 3000
            })
        });

        if (!isDevMode())
            Raven.captureException(error.originalError || error);
    }
}